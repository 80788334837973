import {manifest, version} from '@parcel/service-worker';
const OFFLINE_URL = 'offline.html';

self.addEventListener('install', function(event) {
    console.log('[ServiceWorker] Install');
    event.waitUntil((async () => {
        const cache = await caches.open(version);
        await cache.addAll(manifest);
        await cache.add(new Request(OFFLINE_URL, {cache: 'reload'}));
    })());
    self.skipWaiting();
});
self.addEventListener('activate', (event) => {
    console.log('[ServiceWorker] Activate');
    event.waitUntil((async () => {
        if ('navigationPreload' in self.registration) {
            await self.registration.navigationPreload.enable();
        }
    })());
    self.clients.claim();
});
self.addEventListener('fetch', function(event) {
    if (event.request.mode === 'navigate') {
        event.respondWith((async () => {
            try {
            const preloadResponse = await event.preloadResponse;
            if (preloadResponse) {
                return preloadResponse;
            }
            const networkResponse = await fetch(event.request);
            return networkResponse;
            } catch (error) {
            console.log('[Service Worker] Fetch failed; returning offline page instead.', error);
            const cache = await caches.open(version);
            const cachedResponse = await cache.match(OFFLINE_URL);
            return cachedResponse;
            }
        })());
    }
});